import { createTheme } from "@mantine/core";

export const theme = createTheme({
  fontFamily: "'Open Sans', sans-serif",
  headings: {
    fontFamily: "'Open Sans', sans-serif"
  },
  components: {
    Button: {
      defaultProps: (theme: any) => ({
        color: "#426B1F",
      }),
    },
    Divider: {
      defaultProps: (theme: any) => ({
        color: "#426B1F",
        size: "xl",
      }),
    },
  },
  // ... other theme override properties
});
